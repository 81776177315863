import ImgVisualizer from '../annotator/img-visualizer/ImgVisualizer.vue'
import PdfVisualizer from '../annotator/pdf-visualizer/PdfVisualizer.vue'


 

 
import { 
  api_doc_get,
  base_doc_uri,

} from '@/api/ontologias'


/* http://localhost:8080/#/visor/7D0JH9%2FXNLWAAJ-7D0JH9%2FTPMVXW */ 

export default {
  name: 'Viewer',

  data () {
    return {      
      metadata: null,
      panel: [0, 1, 2],
      original_doc_uri : "",
      docIDpdf : "",
      docIDimg : "",
      url_pdf: api_doc_get+"<"+base_doc_uri+this.$route.params.docid.split('-')[0]+">",
      url_img: api_doc_get+"<"+base_doc_uri+this.$route.params.docid.split('-')[1]+">",
      docId : this.$route.params.docid.split('-')[1]
      
    }
  },
  components: {
    ImgVisualizer,
    PdfVisualizer
  },

  async mounted () {
    console.log("finished mounting")
    this.url = this.get_doc_url()
    console.log("PDF: "+this.$route.params.docid.split('-')[0])
    console.log("IMG: "+this.$route.params.docid.split('-')[1])
    console.log("-------------")
 
  },

  methods: {
    
    get_doc_uri () { 
      return `<${base_doc_uri}${this.$route.params.docid.split('-')[0]}>`   
  
    },
    
    get_doc_url() {                    
      var url = api_doc_get+this.get_doc_uri()             
      return url
    },
  },
}

<template>
  <Visor />
</template>

<script>
  
  import Visor from '../components/viewer/Viewer.vue'  
  export default {
    name: 'VisorV',

    components: {
      Visor,
    },
  }
</script>